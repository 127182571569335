<template>
  <svg viewBox="0 0 12 16">
    <path
      d="M9.2512 0.0684417C9.34907 0.12518 9.42481 0.213432 9.46604 0.318779C9.50727 0.424127 9.51156 0.540343 9.4782 0.648442L7.6772 6.50044H11.0002C11.0979 6.5004 11.1934 6.52896 11.275 6.58259C11.3566 6.63622 11.4207 6.71258 11.4594 6.80223C11.4981 6.89189 11.5098 6.99092 11.4928 7.08709C11.4759 7.18327 11.4312 7.27239 11.3642 7.34344L3.3642 15.8434C3.28679 15.9258 3.18379 15.9794 3.07197 15.9957C2.96016 16.012 2.84613 15.9899 2.74847 15.9331C2.65081 15.8762 2.57528 15.788 2.53419 15.6827C2.4931 15.5775 2.48887 15.4614 2.5222 15.3534L4.3232 9.50044H1.0002C0.902544 9.50048 0.807016 9.47192 0.725405 9.41829C0.643795 9.36466 0.579677 9.2883 0.540965 9.19865C0.502253 9.109 0.490642 9.00997 0.507566 8.91379C0.52449 8.81761 0.569208 8.7285 0.636199 8.65744L8.6362 0.157442C8.71351 0.0752409 8.81636 0.0216103 8.92802 0.00527029C9.03967 -0.0110698 9.15357 0.0108416 9.2512 0.0674417V0.0684417Z"
    />
  </svg>
</template>

<script>
export default {
  name: "ZipperIcon",
};
</script>
